/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import '../Entity/Entity.scss';
import useApi from '../../hooks/useAPI';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import {
  Entity_COLUMNS,
  IEntity,
  MenuListinter,
} from '../../Components/Table/columns';
import { EditIcon, TrashIcon } from '../../assets';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { QUERY_KEYS_ENTITY } from '../../utils/const';
import { toast } from 'react-toastify';
import { DeleteDialog } from '../../Components/Dailog/DeleteDialog';
import FullScreenLoader from '../Loader/FullScreenLoader';
import { dataaccess } from '../../utils/helpers';
import NameContext from '../Context/NameContext';

const Entity = () => {
  const context = useContext(NameContext);
  const { namecolor }: any = context;
  const location = useLocation();
  // const pathSegments = location?.pathname?.split("/").filter(Boolean);
  // const lastSegment = pathSegments[pathSegments?.length - 1]?.toLowerCase();
  const pathSegments = location?.pathname?.split('/').filter(Boolean) || []; // Fallback to an empty array if undefined or null

  const lastSegment =
    pathSegments?.length > 0
      ? pathSegments[pathSegments?.length - 1]?.toLowerCase()
      : ''; // Default value when pathSegments is empty
  const Menulist: any = localStorage.getItem('menulist1');
  const [filteredData, setFilteredData] = useState<MenuListinter | any>([]);
  const tabletools: any = {
    light: '#547476',
    dark: '#00D1D9',
    default: '#547476',
  };

  useEffect(() => {
    setFilteredData(
      dataaccess(Menulist, lastSegment, { urlcheck: '' }, { datatest: '' }),
    );
  }, [Menulist, lastSegment]);

  const EntityURL = QUERY_KEYS_ENTITY.GET_ENTITY;
  const DeleteEntityURL = QUERY_KEYS_ENTITY.ENTITY_DELETE;
  const columns = Entity_COLUMNS;
  const navigate = useNavigate();
  const { getData, deleteData, loading } = useApi();
  const [dataEntity, setDataEntity] = useState<IEntity[]>([]);
  const [dataDelete, setDataDelete] = useState(false);
  const [dataDeleteId, setDataDeleteId] = useState<number>();

  const callAPI = async () => {
    getData(`${EntityURL}`)
      .then((data: { data: IEntity[] }) => {
        if (data.data) {
          setDataEntity(data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          navigate('/');
        }
        toast.error(e?.message, {
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    callAPI();
  }, []);

  const handleEditFile = (id: number) => {
    navigate(`edit-Entity/${id}`);
  };

  const handlecancel = () => {
    setDataDelete(false);
  };

  const handleDeleteFiles = (id: number) => {
    setDataDeleteId(id);
    setDataDelete(true);
  };

  const handleDelete = (id: number | undefined) => {
    deleteData(`${DeleteEntityURL}/${id}`)
      .then((data: { message: string; status: any }) => {
        if (data.status === 200) {
          toast.success('Entity deleted successfully', {
            hideProgressBar: true,
            theme: 'colored',
          });
          callAPI();
          setDataDelete(false);
        } else {
          toast.success(data?.message, {
            hideProgressBar: true,
            theme: 'colored',
          });
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          navigate('/');
        }
        toast.error(e?.message, {
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  };
  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="main-wrapper">
        <div className="main-content">
          <div className="card">
            <div className="card-body">
              <div className="table_wrapper">
                <div className="table_inner">
                  <div
                    className="containerbutton"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6" sx={{ m: 1 }}>
                      <div className="main_title" data-testid="Entity">
                        {' '}
                        Entity
                      </div>
                    </Typography>
                    {/* {filteredData?.[0]?.is_save === true && ( */}
                    {filteredData?.form_data?.is_save === true && (
                      <Button
                        data-testid="Entitybtn"
                        className="mainbutton"
                        variant="contained"
                        component={NavLink}
                        to="add-Entity"
                      >
                        Add Entity
                      </Button>
                    )}
                  </div>
                  <Box marginTop="10px">
                    <MaterialReactTable
                      columns={columns}
                      data={
                        filteredData?.form_data?.is_search ? dataEntity : []
                      }
                      // data={filteredData?.[0]?.is_search ? dataEntity : []}
                      // data={ dataEntity }
                      enableRowVirtualization
                      positionActionsColumn="first"
                      muiTablePaperProps={{
                        elevation: 0,
                      }}
                      enableRowActions
                      displayColumnDefOptions={{
                        'mrt-row-actions': {
                          header: 'Actions',
                          size: 150,
                        },
                      }}
                      renderRowActions={(row) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            gap: '0.5',
                            marginLeft: '-5px',
                            width: '140px',
                          }}
                        >
                          {/* {filteredData?.[0]?.is_update === true && ( */}
                          {filteredData?.form_data?.is_update === true && (
                            <Tooltip arrow placement="right" title="Edit">
                              <IconButton
                                sx={{
                                  width: '35px',
                                  height: '35px',
                                  color: tabletools[namecolor],
                                }}
                                onClick={() => {
                                  handleEditFile(row?.row?.original?.id);
                                }}
                                // disabled={!filteredData?.is_update}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                              sx={{
                                width: '35px',
                                height: '35px',
                                color: tabletools[namecolor],
                              }}
                              onClick={() => {
                                handleDeleteFiles(row?.row?.original?.id);
                              }}
                            >
                              <TrashIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteDialog
        isOpen={dataDelete}
        onCancel={handlecancel}
        onDeleteClick={() => handleDelete(dataDeleteId)}
        title="Delete documents?"
      />
    </>
  );
};

export default Entity;
